@charset "utf-8";

// // Import a Google Font
@import "../node_modules/nord/src/sass/nord.scss";
@import url("https://fonts.googleapis.com/css?family=Frank+Ruhl+Libre&display=swap");

$theme-colors: (
  "night-1": $nord0,
  "night-2": $nord1,
  "night-3": $nord2,
  "night-4": $nord3,
  "snow-1": $nord4,
  "snow-2": $nord5,
  "snow-3": $nord6,
  "frost-1": $nord7,
  "frost-2": $nord8,
  "frost-3": $nord9,
  "frost-4": $nord10,
  "red": $nord11,
  "orange": $nord12,
  "yellow": $nord13,
  "green": $nord14,
  "purple": $nord15,
);

// Update Bulma's global variables
$primary: map-get($theme-colors, "night-2");
$link: map-get($theme-colors, "frost-4");
$info: map-get($theme-colors, "orange");
$success: map-get($theme-colors, "green");
$warning: map-get($theme-colors, "yellow");
$danger: map-get($theme-colors, "red");
$dark: map-get($theme-colors, "night-1");

$family-sans-serif: "Roboto", sans-serif;
$family-secondary: "Frank Ruhl Libre", serif;

.axis,
.chart-text {
  font-family: "Roboto Condensed", sans-serif;
}

.svg-container {
  display: inline-block;
  position: relative;
  width: 100%;
  padding-bottom: 100%; /* aspect ratio */
  vertical-align: top;
  overflow: hidden;
}

.hoverable {
  transition: transform 0.5s ease-in;
}

.hoverable-card:hover {
  box-shadow: 2px 2px 3px rgba($link, 0.8), 0 0 0 2px rgba($link, 0.5);
  transform: scale(1.01);
}

// .logo {
//   border-style: solid;
//   border-width: 1px;
//   padding: 6px;
//   letter-spacing: -0.02em;
// }

// $text:
// // Update some of Bulma's component variables
// $body-background-color: $nord0;
// $control-border-width: 2px;
// $input-border-color: transparent;
// $input-shadow: none;
// // Import only what you need from Bulma
// @import "../node_modules/bulma/sass/utilities/_all.sass";
// @import "../node_modules/bulma/sass/base/_all.sass";
// @import "../node_modules/bulma/sass/elements/button.sass";
// @import "../node_modules/bulma/sass/elements/container.sass";
// @import "../node_modules/bulma/sass/elements/form.sass";
// @import "../node_modules/bulma/sass/elements/title.sass";
// @import "../node_modules/bulma/sass/components/navbar.sass";
// @import "../node_modules/bulma/sass/layout/hero.sass";
// @import "../node_modules/bulma/sass/layout/section.sass";
@import "../node_modules/bulma/bulma.sass";

h1,
h2,
h3,
h4,
h5,
h6,
.title {
  font-family: $family-secondary;
}

:export {
  primary: $primary;
  info: $info;
  success: $success;
  warning: $warning;
  danger: $danger;
  frost-1: map-get($theme-colors, "frost-1");
  frost-2: map-get($theme-colors, "frost-2");
  frost-3: map-get($theme-colors, "frost-3");
  frost-4: map-get($theme-colors, "frost-4");
  night-4: map-get($theme-colors, "night-4");
}
